import { render, staticRenderFns } from "./allCoutseList.vue?vue&type=template&id=a0ac7c26&scoped=true"
import script from "./allCoutseList.vue?vue&type=script&lang=js"
export * from "./allCoutseList.vue?vue&type=script&lang=js"
import style0 from "../asset/css/list.scss?vue&type=style&index=0&id=a0ac7c26&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0ac7c26",
  null
  
)

export default component.exports